/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1UserProfile
 */
export interface V1UserProfile {
    /**
     * 
     * @type {string}
     * @memberof V1UserProfile
     */
    profileImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof V1UserProfile
     */
    profileDetailTargetUri: string;
}

/**
 * Check if a given object implements the V1UserProfile interface.
 */
export function instanceOfV1UserProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "profileDetailTargetUri" in value;

    return isInstance;
}

export function V1UserProfileFromJSON(json: any): V1UserProfile {
    return V1UserProfileFromJSONTyped(json, false);
}

export function V1UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1UserProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profileImageUrl': !exists(json, 'profile_image_url') ? undefined : json['profile_image_url'],
        'profileDetailTargetUri': json['profile_detail_target_uri'],
    };
}

export function V1UserProfileToJSON(value?: V1UserProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile_image_url': value.profileImageUrl,
        'profile_detail_target_uri': value.profileDetailTargetUri,
    };
}

