/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';

/**
 * 
 * @export
 * @interface V1LocalProfile
 */
export interface V1LocalProfile {
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfile
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfile
     */
    profileImageUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1LocalProfile
     */
    backgroundImageUrls: Array<string>;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1LocalProfile
     */
    coordinates?: TypeLatLng;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfile
     */
    businessProfileId?: string;
}

/**
 * Check if a given object implements the V1LocalProfile interface.
 */
export function instanceOfV1LocalProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "categoryName" in value;
    isInstance = isInstance && "backgroundImageUrls" in value;

    return isInstance;
}

export function V1LocalProfileFromJSON(json: any): V1LocalProfile {
    return V1LocalProfileFromJSONTyped(json, false);
}

export function V1LocalProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LocalProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'categoryName': json['category_name'],
        'profileImageUrl': !exists(json, 'profile_image_url') ? undefined : json['profile_image_url'],
        'backgroundImageUrls': json['background_image_urls'],
        'coordinates': !exists(json, 'coordinates') ? undefined : TypeLatLngFromJSON(json['coordinates']),
        'businessProfileId': !exists(json, 'business_profile_id') ? undefined : json['business_profile_id'],
    };
}

export function V1LocalProfileToJSON(value?: V1LocalProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'category_name': value.categoryName,
        'profile_image_url': value.profileImageUrl,
        'background_image_urls': value.backgroundImageUrls,
        'coordinates': TypeLatLngToJSON(value.coordinates),
        'business_profile_id': value.businessProfileId,
    };
}

