/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1LocalStory } from './V1LocalStory';
import {
    V1LocalStoryFromJSON,
    V1LocalStoryFromJSONTyped,
    V1LocalStoryToJSON,
} from './V1LocalStory';

/**
 * 
 * @export
 * @interface V1LocalStorySection
 */
export interface V1LocalStorySection {
    /**
     * 
     * @type {Array<V1LocalStory>}
     * @memberof V1LocalStorySection
     */
    localStories: Array<V1LocalStory>;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStorySection
     */
    viewMoreTargetUri: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStorySection
     */
    uploadStoryTargetUri: string;
}

/**
 * Check if a given object implements the V1LocalStorySection interface.
 */
export function instanceOfV1LocalStorySection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "localStories" in value;
    isInstance = isInstance && "viewMoreTargetUri" in value;
    isInstance = isInstance && "uploadStoryTargetUri" in value;

    return isInstance;
}

export function V1LocalStorySectionFromJSON(json: any): V1LocalStorySection {
    return V1LocalStorySectionFromJSONTyped(json, false);
}

export function V1LocalStorySectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LocalStorySection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localStories': ((json['local_stories'] as Array<any>).map(V1LocalStoryFromJSON)),
        'viewMoreTargetUri': json['view_more_target_uri'],
        'uploadStoryTargetUri': json['upload_story_target_uri'],
    };
}

export function V1LocalStorySectionToJSON(value?: V1LocalStorySection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'local_stories': ((value.localStories as Array<any>).map(V1LocalStoryToJSON)),
        'view_more_target_uri': value.viewMoreTargetUri,
        'upload_story_target_uri': value.uploadStoryTargetUri,
    };
}

