import { IS_PRODUCTION } from "@src/constants"


const logLevel = {
  log: !IS_PRODUCTION ? console.log.bind(window.console) : function(){},
  info: !IS_PRODUCTION ? console.info.bind(window.console) : function(){},
  warn: !IS_PRODUCTION ? console.warn.bind(window.console) : function(){},
  error: !IS_PRODUCTION ? console.error.bind(window.console) : function(){},
}

export { logLevel }
