import { type LocalMapStepKeys } from '@src/hooks/useStepRouter'

/** 홈 스탭 검색 input height px*/
export const HOME_SEARCH_INPUT_HEIGHT_PX = 48
/** 홈 스탭 검색 input 상하 padding px */
export const HOME_SEARCH_INPUT_VERTICAL_PADDING_PX = 12
/** 검색 input과 칩 사이의 거리 */
export const HOME_SEARCH_INPUT_CHIP_DISTANCE_PX = 10
/** 홈 스탭 검색 추천 칩 height px */
export const HOME_SEARCH_RECOMMENDATION_CHIP_HEIGHT_PX = 32
/** 검색 스탭 검색 영역 크기 px */
export const SEARCH_SEARCH_INPUT_AREA_HEIGHT_PX = 56

/** 홈 스탭 상단 영역 height px */
const HOME_TOP_AREA_HEIGHT_PX =
  HOME_SEARCH_INPUT_HEIGHT_PX + // 검색 input
  HOME_SEARCH_INPUT_VERTICAL_PADDING_PX + // 검색 input top padding
  HOME_SEARCH_INPUT_CHIP_DISTANCE_PX + // 검색 input과 칩 사이의 거리
  HOME_SEARCH_RECOMMENDATION_CHIP_HEIGHT_PX // 추천 칩 height

/** 검색 상단 영역 height px */
const SEARCH_TOP_AREA_HEIGHT_PX = SEARCH_SEARCH_INPUT_AREA_HEIGHT_PX

/** 지도 스탭에 따른 상단영역 height size px */
export const MAP_TOP_AREA_SIZE_MAPPER: Record<LocalMapStepKeys, number> = {
  home: HOME_TOP_AREA_HEIGHT_PX,
  search: SEARCH_TOP_AREA_HEIGHT_PX,
  searchTyping: SEARCH_TOP_AREA_HEIGHT_PX,
}
