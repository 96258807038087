/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1CommerceCouponEventType } from './V1CommerceCouponEventType';
import {
    V1CommerceCouponEventTypeFromJSON,
    V1CommerceCouponEventTypeFromJSONTyped,
    V1CommerceCouponEventTypeToJSON,
} from './V1CommerceCouponEventType';
import type { V1CommerceCouponUserStatus } from './V1CommerceCouponUserStatus';
import {
    V1CommerceCouponUserStatusFromJSON,
    V1CommerceCouponUserStatusFromJSONTyped,
    V1CommerceCouponUserStatusToJSON,
} from './V1CommerceCouponUserStatus';
import type { V1LocalProfile } from './V1LocalProfile';
import {
    V1LocalProfileFromJSON,
    V1LocalProfileFromJSONTyped,
    V1LocalProfileToJSON,
} from './V1LocalProfile';

/**
 * 
 * @export
 * @interface V1CommerceCoupon
 */
export interface V1CommerceCoupon {
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    name: string;
    /**
     * 
     * @type {V1LocalProfile}
     * @memberof V1CommerceCoupon
     */
    localProfile: V1LocalProfile;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    issuedCount?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    usedCount: string;
    /**
     * 
     * @type {Date}
     * @memberof V1CommerceCoupon
     */
    expiredAt: Date;
    /**
     * 
     * @type {V1CommerceCouponEventType}
     * @memberof V1CommerceCoupon
     */
    commerceCouponEventType: V1CommerceCouponEventType;
    /**
     * 
     * @type {boolean}
     * @memberof V1CommerceCoupon
     */
    isBrand: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    brandLogoImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    coverImageUrl?: string;
    /**
     * 
     * @type {V1CommerceCouponUserStatus}
     * @memberof V1CommerceCoupon
     */
    commerceCouponUserStatus: V1CommerceCouponUserStatus;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    targetUri: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    impressionLoggingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommerceCoupon
     */
    clickLoggingUrl?: string;
}

/**
 * Check if a given object implements the V1CommerceCoupon interface.
 */
export function instanceOfV1CommerceCoupon(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "localProfile" in value;
    isInstance = isInstance && "usedCount" in value;
    isInstance = isInstance && "expiredAt" in value;
    isInstance = isInstance && "commerceCouponEventType" in value;
    isInstance = isInstance && "isBrand" in value;
    isInstance = isInstance && "commerceCouponUserStatus" in value;
    isInstance = isInstance && "targetUri" in value;

    return isInstance;
}

export function V1CommerceCouponFromJSON(json: any): V1CommerceCoupon {
    return V1CommerceCouponFromJSONTyped(json, false);
}

export function V1CommerceCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CommerceCoupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'localProfile': V1LocalProfileFromJSON(json['local_profile']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'issuedCount': !exists(json, 'issued_count') ? undefined : json['issued_count'],
        'usedCount': json['used_count'],
        'expiredAt': (new Date(json['expired_at'])),
        'commerceCouponEventType': V1CommerceCouponEventTypeFromJSON(json['commerce_coupon_event_type']),
        'isBrand': json['is_brand'],
        'brandLogoImageUrl': !exists(json, 'brand_logo_image_url') ? undefined : json['brand_logo_image_url'],
        'coverImageUrl': !exists(json, 'cover_image_url') ? undefined : json['cover_image_url'],
        'commerceCouponUserStatus': V1CommerceCouponUserStatusFromJSON(json['commerce_coupon_user_status']),
        'targetUri': json['target_uri'],
        'impressionLoggingUrl': !exists(json, 'impression_logging_url') ? undefined : json['impression_logging_url'],
        'clickLoggingUrl': !exists(json, 'click_logging_url') ? undefined : json['click_logging_url'],
    };
}

export function V1CommerceCouponToJSON(value?: V1CommerceCoupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'local_profile': V1LocalProfileToJSON(value.localProfile),
        'description': value.description,
        'issued_count': value.issuedCount,
        'used_count': value.usedCount,
        'expired_at': (value.expiredAt.toISOString()),
        'commerce_coupon_event_type': V1CommerceCouponEventTypeToJSON(value.commerceCouponEventType),
        'is_brand': value.isBrand,
        'brand_logo_image_url': value.brandLogoImageUrl,
        'cover_image_url': value.coverImageUrl,
        'commerce_coupon_user_status': V1CommerceCouponUserStatusToJSON(value.commerceCouponUserStatus),
        'target_uri': value.targetUri,
        'impression_logging_url': value.impressionLoggingUrl,
        'click_logging_url': value.clickLoggingUrl,
    };
}

