/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';
import type { V1SearchResultLocalProfilePoiCategory } from './V1SearchResultLocalProfilePoiCategory';
import {
    V1SearchResultLocalProfilePoiCategoryFromJSON,
    V1SearchResultLocalProfilePoiCategoryFromJSONTyped,
    V1SearchResultLocalProfilePoiCategoryToJSON,
} from './V1SearchResultLocalProfilePoiCategory';

/**
 * 
 * @export
 * @interface V1SearchResultLocalProfile
 */
export interface V1SearchResultLocalProfile {
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    regionName: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    profileImage?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    backgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof V1SearchResultLocalProfile
     */
    reviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof V1SearchResultLocalProfile
     */
    followCount: number;
    /**
     * 
     * @type {number}
     * @memberof V1SearchResultLocalProfile
     */
    calculatedDistanceMeter?: number;
    /**
     * 
     * @type {Date}
     * @memberof V1SearchResultLocalProfile
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof V1SearchResultLocalProfile
     */
    isStoreOnSale: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1SearchResultLocalProfile
     */
    isReservable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1SearchResultLocalProfile
     */
    hasCoupon: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    addressRoad: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultLocalProfile
     */
    addressLand: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SearchResultLocalProfile
     */
    disableOnMap: boolean;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1SearchResultLocalProfile
     */
    geoPoint?: TypeLatLng;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1SearchResultLocalProfile
     */
    fallbackGeoPoint?: TypeLatLng;
    /**
     * 
     * @type {V1SearchResultLocalProfilePoiCategory}
     * @memberof V1SearchResultLocalProfile
     */
    poiCategory?: V1SearchResultLocalProfilePoiCategory;
    /**
     * 
     * @type {number}
     * @memberof V1SearchResultLocalProfile
     */
    reviewStarRatingAverage: number;
}

/**
 * Check if a given object implements the V1SearchResultLocalProfile interface.
 */
export function instanceOfV1SearchResultLocalProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "categoryName" in value;
    isInstance = isInstance && "regionName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "reviewCount" in value;
    isInstance = isInstance && "followCount" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "isStoreOnSale" in value;
    isInstance = isInstance && "isReservable" in value;
    isInstance = isInstance && "hasCoupon" in value;
    isInstance = isInstance && "addressRoad" in value;
    isInstance = isInstance && "addressLand" in value;
    isInstance = isInstance && "disableOnMap" in value;
    isInstance = isInstance && "reviewStarRatingAverage" in value;

    return isInstance;
}

export function V1SearchResultLocalProfileFromJSON(json: any): V1SearchResultLocalProfile {
    return V1SearchResultLocalProfileFromJSONTyped(json, false);
}

export function V1SearchResultLocalProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchResultLocalProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'categoryName': json['category_name'],
        'regionName': json['region_name'],
        'profileImage': !exists(json, 'profile_image') ? undefined : json['profile_image'],
        'backgroundImage': !exists(json, 'background_image') ? undefined : json['background_image'],
        'description': json['description'],
        'reviewCount': json['review_count'],
        'followCount': json['follow_count'],
        'calculatedDistanceMeter': !exists(json, 'calculated_distance_meter') ? undefined : json['calculated_distance_meter'],
        'createdAt': (new Date(json['created_at'])),
        'isStoreOnSale': json['is_store_on_sale'],
        'isReservable': json['is_reservable'],
        'hasCoupon': json['has_coupon'],
        'addressRoad': json['address_road'],
        'addressLand': json['address_land'],
        'disableOnMap': json['disable_on_map'],
        'geoPoint': !exists(json, 'geo_point') ? undefined : TypeLatLngFromJSON(json['geo_point']),
        'fallbackGeoPoint': !exists(json, 'fallback_geo_point') ? undefined : TypeLatLngFromJSON(json['fallback_geo_point']),
        'poiCategory': !exists(json, 'poi_category') ? undefined : V1SearchResultLocalProfilePoiCategoryFromJSON(json['poi_category']),
        'reviewStarRatingAverage': json['review_star_rating_average'],
    };
}

export function V1SearchResultLocalProfileToJSON(value?: V1SearchResultLocalProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'category_name': value.categoryName,
        'region_name': value.regionName,
        'profile_image': value.profileImage,
        'background_image': value.backgroundImage,
        'description': value.description,
        'review_count': value.reviewCount,
        'follow_count': value.followCount,
        'calculated_distance_meter': value.calculatedDistanceMeter,
        'created_at': (value.createdAt.toISOString()),
        'is_store_on_sale': value.isStoreOnSale,
        'is_reservable': value.isReservable,
        'has_coupon': value.hasCoupon,
        'address_road': value.addressRoad,
        'address_land': value.addressLand,
        'disable_on_map': value.disableOnMap,
        'geo_point': TypeLatLngToJSON(value.geoPoint),
        'fallback_geo_point': TypeLatLngToJSON(value.fallbackGeoPoint),
        'poi_category': V1SearchResultLocalProfilePoiCategoryToJSON(value.poiCategory),
        'review_star_rating_average': value.reviewStarRatingAverage,
    };
}

