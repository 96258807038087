/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';
import type { UserLocationPlaceType } from './UserLocationPlaceType';
import {
    UserLocationPlaceTypeFromJSON,
    UserLocationPlaceTypeFromJSONTyped,
    UserLocationPlaceTypeToJSON,
} from './UserLocationPlaceType';

/**
 * 
 * @export
 * @interface V1UserLocation
 */
export interface V1UserLocation {
    /**
     * 
     * @type {string}
     * @memberof V1UserLocation
     */
    id?: string;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1UserLocation
     */
    point: TypeLatLng;
    /**
     * 
     * @type {UserLocationPlaceType}
     * @memberof V1UserLocation
     */
    place: UserLocationPlaceType;
    /**
     * 
     * @type {string}
     * @memberof V1UserLocation
     */
    encryptedId: string;
}

/**
 * Check if a given object implements the V1UserLocation interface.
 */
export function instanceOfV1UserLocation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "place" in value;
    isInstance = isInstance && "encryptedId" in value;

    return isInstance;
}

export function V1UserLocationFromJSON(json: any): V1UserLocation {
    return V1UserLocationFromJSONTyped(json, false);
}

export function V1UserLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1UserLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'point': TypeLatLngFromJSON(json['point']),
        'place': UserLocationPlaceTypeFromJSON(json['place']),
        'encryptedId': json['encrypted_id'],
    };
}

export function V1UserLocationToJSON(value?: V1UserLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'point': TypeLatLngToJSON(value.point),
        'place': UserLocationPlaceTypeToJSON(value.place),
        'encrypted_id': value.encryptedId,
    };
}

