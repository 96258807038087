/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1BusinessPost } from './V1BusinessPost';
import {
    V1BusinessPostFromJSON,
    V1BusinessPostFromJSONTyped,
    V1BusinessPostToJSON,
} from './V1BusinessPost';

/**
 * 
 * @export
 * @interface V1RecommendationBusinessPostSection
 */
export interface V1RecommendationBusinessPostSection {
    /**
     * 
     * @type {Array<V1BusinessPost>}
     * @memberof V1RecommendationBusinessPostSection
     */
    businessPosts: Array<V1BusinessPost>;
    /**
     * 
     * @type {string}
     * @memberof V1RecommendationBusinessPostSection
     */
    viewMoreTargetUri: string;
}

/**
 * Check if a given object implements the V1RecommendationBusinessPostSection interface.
 */
export function instanceOfV1RecommendationBusinessPostSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "businessPosts" in value;
    isInstance = isInstance && "viewMoreTargetUri" in value;

    return isInstance;
}

export function V1RecommendationBusinessPostSectionFromJSON(json: any): V1RecommendationBusinessPostSection {
    return V1RecommendationBusinessPostSectionFromJSONTyped(json, false);
}

export function V1RecommendationBusinessPostSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1RecommendationBusinessPostSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessPosts': ((json['business_posts'] as Array<any>).map(V1BusinessPostFromJSON)),
        'viewMoreTargetUri': json['view_more_target_uri'],
    };
}

export function V1RecommendationBusinessPostSectionToJSON(value?: V1RecommendationBusinessPostSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business_posts': ((value.businessPosts as Array<any>).map(V1BusinessPostToJSON)),
        'view_more_target_uri': value.viewMoreTargetUri,
    };
}

