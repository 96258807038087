import { HOME_SEARCH_INPUT_HEIGHT_PX } from '@src/pages/home-activity/_constants/size'
import { Skeleton } from '@src/react-utils/skeleton'

import * as css from './SearchInput.css'

const SearchInputSkeleton = () => {
  const styleType = 'transparent'

  return (
    <div
      className={css.container({
        styleType,
      })}
    >
      <Skeleton width="100%" height={`${HOME_SEARCH_INPUT_HEIGHT_PX}px`} />
    </div>
  )
}

export default SearchInputSkeleton
