/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1FeedItem } from './V1FeedItem';
import {
    V1FeedItemFromJSON,
    V1FeedItemFromJSONTyped,
    V1FeedItemToJSON,
} from './V1FeedItem';

/**
 * 
 * @export
 * @interface V1ListFeedItemsByViewTypesResponse
 */
export interface V1ListFeedItemsByViewTypesResponse {
    /**
     * 
     * @type {Array<V1FeedItem>}
     * @memberof V1ListFeedItemsByViewTypesResponse
     */
    feedItems: Array<V1FeedItem>;
}

/**
 * Check if a given object implements the V1ListFeedItemsByViewTypesResponse interface.
 */
export function instanceOfV1ListFeedItemsByViewTypesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "feedItems" in value;

    return isInstance;
}

export function V1ListFeedItemsByViewTypesResponseFromJSON(json: any): V1ListFeedItemsByViewTypesResponse {
    return V1ListFeedItemsByViewTypesResponseFromJSONTyped(json, false);
}

export function V1ListFeedItemsByViewTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListFeedItemsByViewTypesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedItems': ((json['feed_items'] as Array<any>).map(V1FeedItemFromJSON)),
    };
}

export function V1ListFeedItemsByViewTypesResponseToJSON(value?: V1ListFeedItemsByViewTypesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feed_items': ((value.feedItems as Array<any>).map(V1FeedItemToJSON)),
    };
}

